import React, { useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { loader } from "graphql.macro";
import { graphql } from "react-apollo";
import { PictureAsPdf } from "@material-ui/icons";
import moment from "moment";

import withTranslator from "./hocs/withTranslator";
import client from "../apollo";

const invoiceListQuery = loader("../queries/InvoiceList.graphql");
const brInvoiceDownloadUrlQuery = loader(
  "../queries/BrInvoiceDownloadUrl.graphql"
);

const InvoiceList = (props) => {
  const { data, i18n, loadingOverride } = props;
  const [invoicesDialogOpen, setInvoicesDialogOpen] = useState(false);
  const [downloadInvoiceLoading, setDownloadInvoiceLoading] = useState(false);
  if (data.loading || loadingOverride) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "300px" }}
      >
        <CircularProgress />
      </Grid>
    );
  }
  if (data.error) {
    return (
      <Alert severity="error" style={{ width: "100%" }}>
        Apologies! There was an error calculating your accounts performance.
        Please try back later.
      </Alert>
    );
  }

  const handleDownloadInvoice = (brInvoiceId) => {
    setDownloadInvoiceLoading(true);
    client
      .query({
        query: brInvoiceDownloadUrlQuery,
        variables: { brInvoiceId },
        fetchPolicy: "no-cache",
      })
      .then(
        (resp) => {
          if (resp.loading) {
            return null;
          }
          if (resp.error) {
            setDownloadInvoiceLoading(false);
            return null;
          }
          const downloadLink = document.createElement("a");
          downloadLink.download = `fatura.pdf`;
          downloadLink.href = resp.data.brInvoiceDownloadUrl;
          document.body.append(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          setDownloadInvoiceLoading(false);
          return null;
        },
        (err) => {
          setDownloadInvoiceLoading(false);
          console.error(err);
        }
      );
  };

  const filteredInvoices =
    data.consumerUnit.recentBrCreditCompensations?.filter(
      (el) => !!el.brInvoice
    );
  if (!filteredInvoices?.[0]) {
    return (
      <Alert severity="info" style={{ width: "100%" }}>
        {i18n.t("noInvoices", "No Invoices")}
      </Alert>
    );
  }
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        direction="column"
        style={{ minHeight: "300px" }}
      >
        <Grid item>
          <List style={{ width: "100%" }} dense>
            {filteredInvoices.map((creditCompensation) => (
              <ListItem key={`invoice-list-item-${creditCompensation.id}`}>
                <ListItemAvatar>
                  <Avatar
                    style={{
                      backgroundColor:
                        creditCompensation.brInvoice.paymentStatus.iconColor,
                    }}
                  >
                    <Icon
                      className={
                        creditCompensation.brInvoice.paymentStatus.iconClass
                      }
                      style={{
                        color: "#fff",
                        width: "auto",
                      }}
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={moment(
                    creditCompensation.brInvoice.brBillingCycle.billingMonth
                  ).format("MMMM YYYY")}
                  secondary={creditCompensation.brInvoice.paymentStatus.label}
                />
                <ListItemSecondaryAction>
                  {/* <IconButton edge="end" aria-label="view">
              <Visibility />
            </IconButton> */}
                  <IconButton
                    edge="end"
                    aria-label="download"
                    size="small"
                    color="primary"
                    disabled={
                      (!creditCompensation.brInvoice.stripeInvoiceId &&
                        !creditCompensation.brInvoice.starkBankBoletoId) ||
                      downloadInvoiceLoading
                    }
                    onClick={() =>
                      handleDownloadInvoice(creditCompensation.brInvoice.id)
                    }
                  >
                    <PictureAsPdf />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
        {/* TODO: reimplement once it's not under development */}
        {/* <Grid item>
          <Grid container justifyContent="flex-end">
            <Button
              //   size="small"
              color="primary"
              style={{ float: "right" }}
              onClick={() => setInvoicesDialogOpen(true)}
            >
              {i18n.t("seeAllInvoices", "See all invoices")}
            </Button>
          </Grid>
        </Grid> */}
      </Grid>
      <Dialog
        open={!!invoicesDialogOpen}
        onClose={() => setInvoicesDialogOpen(false)}
      >
        <DialogContent>Under Development</DialogContent>
      </Dialog>
    </>
  );
};

export default graphql(invoiceListQuery)(withTranslator(InvoiceList));
