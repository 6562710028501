import React, { useState } from "react";
// import CookieConsent from "react-cookie-consent";
// import { useTheme } from "@material-ui/core/styles";

import { Grid } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import ContactDialog from "../ContactDialog";
// import Register from "../Register";
import SavingsCalculatorDialog from "../SavingsCalculatorDialog";

const withHeader = (WrappedComponent, options) => {
  const HOC = (props) => {
    let location = useLocation();
    const [contactFormOpen, setContactFormOpen] = useState(false);
    const [savingsCalculatorOpen, setSavingsCalculatorOpen] = useState(false);
    // const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    // const [registerOpen, setRegisterOpen] = useState(false);
    let offset = 80;
    if (options?.footer) {
      // offset += 139;
    }
    return (
      <>
        {/* <CookieConsent
          location="bottom"
          buttonText="Eu Aceito"
          cookieName="energea-br-consent"
          style={{
            padding: fullScreen ? "0 0 0 80px" : "0 80px",
            background: "rgb(71, 91, 115)",
            zIndex: 10000,
          }}
          acceptOnScroll
          acceptOnScrollPercentage={95}
          contentStyle={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          buttonStyle={{
            color: "#fff",
            backgroundColor: "#3AB364",
            fontWeight: "bold",
            borderRadius: "50px",
            "&:hover": {
              backgroundColor: "#009A7C !important",
              color: "#fff !important",
            },
            padding: "1rem 2rem",
          }}
          expires={365}
          debug={true}
        >
          Este site usa cookies para melhorar a experiência do usuário. Não
          armazenamos nenhuma informação de identificação, portanto, todos os
          dados coletados permanecem anônimos.
        </CookieConsent> */}
        <Header
          opaque={options?.opaque}
          location={location}
          savingsCalculatorOpen={savingsCalculatorOpen}
          setSavingsCalculatorOpen={setSavingsCalculatorOpen}
          contactFormOpen={contactFormOpen}
          setContactFormOpen={setContactFormOpen}
        />
        <Grid
          container
          style={{
            minHeight: `calc(100vh - ${offset}px)`, // 100vh",
            // position: props.fullPage ? "absolute" : undefined,
            marginTop: options?.fullPage ? "0px" : "5rem",
            // overflow: props.fullPage ? "scroll" : undefined,
          }}
        >
          <WrappedComponent
            {...props}
            savingsCalculatorOpen={savingsCalculatorOpen}
            setSavingsCalculatorOpen={setSavingsCalculatorOpen}
            // registerOpen={registerOpen}
            // setRegisterOpen={setRegisterOpen}
            contactFormOpen={contactFormOpen}
            setContactFormOpen={setContactFormOpen}
          />
        </Grid>
        {options?.footer && (
          <Footer
            savingsCalculatorOpen={savingsCalculatorOpen}
            setSavingsCalculatorOpen={setSavingsCalculatorOpen}
            // registerOpen={registerOpen}
            // setRegisterOpen={setRegisterOpen}
            contactFormOpen={contactFormOpen}
            setContactFormOpen={setContactFormOpen}
          />
        )}
        <ContactDialog
          open={contactFormOpen}
          onClose={() => setContactFormOpen(false)}
        />
        <SavingsCalculatorDialog
          open={savingsCalculatorOpen}
          // registerOpen={registerOpen}
          // setRegisterOpen={setRegisterOpen}
          onClose={() => setSavingsCalculatorOpen(false)}
        />
        {/* <Register open={registerOpen} onClose={() => setRegisterOpen(false)} /> */}
      </>
    );
  };
  return HOC;
};

export default withHeader;
