import React, { Component } from "react";
import { Link } from "react-router-dom";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Paper,
  Typography,
  withMobileDialog,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";
// import { loader } from "graphql.macro";
import withTranslator from "./hocs/withTranslator";

// const faqQuery = loader("../queries/FAQ.graphql");

const styles = (theme) => ({
  accordion: {
    color: theme.palette.primary.main,
    "&:hover": {
      color: "#fff !important",
    },
  },
  accordionBorder: {
    "&:before": {
      backgroundColor: "rgba(255,255,255,.12)",
    },
  },
  selected: {
    background: "#3AB364",
  },
  categoryButtons: {
    color: "#fff",
    borderRadius: "50px",
    "&:hover": {
      background: "#3AB364",
    },
    border: "1px solid rgba(255, 255, 255, 0.2)",
  },
});
const faqCategories = [
  {
    id: "frequentes",
    label: "Mais frequentes",
  },
  {
    id: "sobre",
    label: "Sobre a Energea Brasil",
  },
  {
    id: "detalhes",
    label: "Detalhes da adesão",
  },
  {
    id: "pagamentos",
    label: "Pagamentos",
  },
  {
    id: "servico",
    label: "Sobre o serviço",
  },
];

const faqObjected = [
  {
    question: "O que devo fazer para participar da Usina Solar?",
    answer: (
      <>
        <Typography>
          Primeiro é necessário realizar a adesão em nosso sistema se
          cadastrando online, diretamente em nosso site, ou através de um dos
          nossos canais parceiros.
        </Typography>
        <Typography>
          Após o preenchimento dos dados necessários e o envio da conta de luz
          mais recente, enviaremos um e-mail confirmando o recebimento do seu
          cadastro e seguiremos com o processo de análise, que está sujeito à
          aprovação. Sendo aprovado, você receberá o contrato por e-mail, para
          assinatura eletrônica.
        </Typography>
      </>
    ),
    categoryId: ["detalhes", "frequentes"],
  },
  {
    question: "Quais são os requisitos para a adesão?",
    answer: (
      <>
        <Typography>
          <ul>
            <li>
              A unidade consumidora deverá estar dentro da área atendida pelas
              Distribuidoras CEMIG ou LIGHT;
            </li>
            <li>A unidade consumidora deverá ser do grupo B (baixa tensão);</li>
            <li>
              É preciso ter um consumo mensal de energia elétrica equivalente a,
              pelo menos, 400 kWh ou R$ 500,00 na conta de energia mais recente;
            </li>
            <li>
              Para pessoa física: Informar o CPF do titular da conta de luz
              cadastrado na Distribuidora de energia e enviar a conta de luz
              mais recente;
            </li>
            <li>
              Para pessoa jurídica: Informar o CNPJ cadastrado na Distribuidora
              de energia e enviar a conta de luz mais recente.
            </li>
          </ul>
        </Typography>
        <Typography>
          O CPF/CNPJ informado deve estar ativo e adimplente (sem débitos ou
          acordos) junto à Distribuidora de energia.
        </Typography>
        <Typography>
          Após serem submetidos estes dados, será feita uma análise do perfil do
          cliente.
        </Typography>
      </>
    ),
    categoryId: ["detalhes", "frequentes"],
  },
  {
    question: "Quais as vantagens da minha participação na Usina Solar?",
    answer: (
      <>
        <Typography>
          Uma das vantagens é que você terá uma economia mensal na conta de
          energia. Com desconto mensal, calculado em cima da quantidade de
          energia gerada pela Usina Solar FV e entregue para a Distribuidora de
          energia em seu favor .
        </Typography>
        <Typography>
          O cálculo dos créditos é feito por meio de um processo de compensação.
          Na conta da Distribuidora, os créditos serão informados como “Energia
          Injetada”, apresentados em kWh e calculados em Reais.
        </Typography>
      </>
    ),
    categoryId: ["servico", "frequentes"],
  },
  {
    question: "Quem pode assinar o termo de adesão ao Consórcio Energea?",
    answer: (
      <>
        <Typography>
          <ul>
            <li>
              Para pessoa física, somente o titular da conta cadastrada poderá
              assinar o Termo de Adesão
            </li>
            <li>
              Para pessoa jurídica, os responsáveis legais por este CNPJ (sócios
              ou administradores) poderão assinar o Termo de Adesão.
            </li>
          </ul>
        </Typography>
      </>
    ),
    categoryId: ["detalhes"],
  },
  {
    question:
      "É necessário pagar algum valor para realizar a adesão ou para começar a receber os créditos?",
    answer: (
      <>
        <Typography>
          Não. A adesão é realizada de maneira totalmente digital e gratuita,
          não necessitando, por parte do cliente, qualquer tipo de pagamento
          para finalizar o processo.
        </Typography>
      </>
    ),
    categoryId: ["frequentes"],
  },
  {
    question:
      'O que é o processo de "Compensação de Crédito" e como ele é usado?',
    answer: (
      <>
        <Typography>
          Através da Resolução Normativa nº 482 de 2012, regulamentada pela
          ANEEL, consumidores de energia elétrica em todo o país passaram a ter
          acesso à produção de sua própria energia elétrica, seja pela
          instalação de painéis solares no telhado do seu estabelecimento ou
          participando de uma Fazenda/Usina Solar.
        </Typography>
        <Typography>
          A energia proveniente desses sistemas é injetada na rede de
          distribuição e passa a gerar créditos para o gerador. A maneira na
          qual a compensação de crédito ocorre é muito simples e automática.
          Toda a energia gerada em nossa Usina Solar FV é injetada na rede da
          Distribuidora de energia (Cemig, Light, entre outras), criando
          créditos de energia (medidos em quilowatt-hora) que são distribuídos
          em favor do cliente associado Energea.
        </Typography>
        <Typography>
          Como você passa a ser responsável por um lote da Usina Solar FV, esses
          créditos passam a ser gerados “por você/a seu favor”, e são usados
          para descontar o seu consumo total de KWh proveniente da Distribuidora
          de Energia.
        </Typography>
      </>
    ),
    categoryId: ["servico"],
  },
  {
    question: "Quantos kWh eu vou produzir na Usina Solar?",
    answer: (
      <>
        <Typography>
          Cada cliente terá uma cota dentro da Usina Solar FV e isso é
          determinado pela análise da sua conta de energia atual, onde é
          verificada a média de consumo mensal (em kWh) de cada cliente. Dessa
          forma, cada cliente poderá receber uma quantidade de kWh adequada, de
          acordo com o seu consumo.
        </Typography>
      </>
    ),
    categoryId: ["servico"],
  },
  {
    question: "Minha conta de energia vai zerar?",
    answer: (
      <>
        <Typography>
          Não. Sua conta virá muito mais baixa, mas nunca irá zerar. A
          Distribuidora de energia cobra algumas taxas referentes aos serviços
          de distribuição de energia até a sua casa, através da rede elétrica.
        </Typography>
      </>
    ),
    categoryId: ["servico"],
  },
  {
    question: "Como eu recebo os créditos na minha conta?",
    answer: (
      <>
        <Typography>
          Quando iniciarem as atividades da Usina Solar FV, a Distribuidora de
          energia adicionará na sua conta de luz a informação do total de
          Energia Injetada (quantidade de kWh que você consumiu da Fazenda/Usina
          Solar)
        </Typography>
        <Typography>
          Este valor também será mostrado em Reais e não será cobrado na conta
          de luz, pois deverá ser pago diretamente para o Consórcio Energea.
        </Typography>
        <Typography>
          Enviaremos uma fatura com todas as informações da energia gerada e
          consumida por você, já com o desconto concedido na sua adesão.
        </Typography>
      </>
    ),
    categoryId: ["servico"],
  },
  {
    question:
      "Tenho placas de energia solar no meu imóvel, posso participar da Usina Energea?",
    answer: (
      <>
        <Typography>
          Sim, existe essa possibilidade. Porém, somente a análise da sua conta
          de energia poderá definir se você pode participar da usina ou não.
        </Typography>
      </>
    ),
    categoryId: ["servico"],
  },
  {
    question: "O desconto é aplicado na conta toda?",
    answer: (
      <>
        <Typography>
          Não. O desconto concedido na sua adesão é aplicado apenas sob o valor
          do crédito recebido/energia injetada, descrito em Reais, na sua conta
          de luz e fatura da Usina Solar
        </Typography>
      </>
    ),
    categoryId: ["pagamentos"],
  },
  {
    question: "Como e para quem eu pago pelos créditos recebidos?",
    answer: (
      <>
        <Typography>
          Os créditos recebidos de energia injetada deverão ser pagos
          diretamente para o Consórcio Energea que você aderiu ao assinar nosso
          contrato. Uma fatura com todo o demonstrativo de geração e de consumo
          será emitida e enviada ao email de contato informado no ato da adesão.
          Qualquer valor devido à Distribuidora, como impostos, deverá continuar
          sendo pago normalmente na conta de luz.
        </Typography>
      </>
    ),
    categoryId: ["pagamentos"],
  },
  {
    question:
      "Posso acompanhar o meu consumo e valores a pagar para a usina, online?",
    answer: (
      <>
        <Typography>
          Sim. Disponibilizamos o portal do cliente que pode ser acessado
          clicando em “Entrar”, no menu do nosso website e também pelo
          aplicativo que o cliente é orientado a baixar para acompanhar sua
          conta da Energea.
        </Typography>
      </>
    ),
    categoryId: ["pagamentos"],
  },
  {
    question:
      "Posso me cadastrar mesmo a minha conta estando em nome de outra pessoa?",
    answer: (
      <>
        <Typography>
          Não. O cadastro e a assinatura do Termo de Adesão devem ser realizados
          pelo titular da conta de energia. Uma alternativa a essa opção, seria
          a solicitação da troca de titularidade junto à Distribuidora de
          energia.
        </Typography>
      </>
    ),
    categoryId: ["detalhes"],
  },
  {
    question: "Quando começarei a receber os benefícios da Usina Solar?",
    answer: (
      <>
        <Typography>
          Tendo concluído o processo de adesão, você será notificado por e-mail
          quando a Usina Solar FV entrar em fase de operação. A partir deste
          momento, os créditos começarão a ser considerados em sua conta em um
          prazo de 30 a 60 dias.
        </Typography>
      </>
    ),
    categoryId: ["servico"],
  },
  {
    question: "Por que eu preciso enviar a imagem da minha conta de energia?",
    answer: (
      <>
        <Typography>
          Para darmos continuidade em seu cadastro solicitamos o envio de uma
          imagem nítida da sua última conta de energia, onde seja fácil
          identificarmos o valor de consumo em reais e em kWh, os códigos de
          instalação e de cliente, além do endereço e do nome do titular da
          conta. A análise da sua conta é importante para descobrirmos qual a
          quantidade de kWh que será adequada ao seu consumo.
        </Typography>
      </>
    ),
    categoryId: ["detalhes", "frequentes"],
  },
  {
    question:
      "Será necessário realizar algum tipo de instalação ou visita em meu imóvel?",
    answer: (
      <>
        <Typography>
          Não. Todo equipamento necessário para o funcionamento e manutenção da
          Usina Solar FV é coberto pelo Consórcio Energea e instalado na Usina
          Solar. A Energea não solicita nenhum tipo de visita ao imóvel do
          cliente.
        </Typography>
      </>
    ),
    categoryId: ["frequentes"],
  },
  {
    question:
      "Como saberei que o processo de adesão foi concluído com sucesso?",
    answer: (
      <>
        <Typography>
          Durante todo o processo você receberá e-mails e comunicações da
          Energea, desde a análise até a aprovação.
        </Typography>
      </>
    ),
    categoryId: ["detalhes"],
  },
  {
    question:
      "O contrato exige fidelidade? Quando e como posso cancelar o contrato?",
    answer: (
      <>
        <Typography>
          Ao associar-se à Usina Solar do Consórcio Energea, você estará livre
          de taxas escondidas. Nosso contrato é claro e não cobramos nenhuma
          taxa de adesão ou de fidelidade.
        </Typography>
        <Typography>
          O cliente deverá notificar formalmente o desejo de saída e
          cancelamento da adesão ao Consórcio Energea, com 60 dias de
          antecedência, através do e-mail contato@energea.com.
        </Typography>
        <Typography>
          Esse prazo é necessário para que tanto o Consórcio Energea, quanto a
          sua Distribuidora de energia, possam concluir sua total desconexão de
          recebimento/cobrança de créditos.
        </Typography>
      </>
    ),
    categoryId: ["detalhes"],
  },
  {
    question:
      "Como ficará o relacionamento com a minha Distribuidora de energia após a minha adesão à Usina Solar?",
    answer: (
      <>
        <Typography>
          Como a Distribuidora de energia permanece como agente responsável pela
          distribuição da energia mesmo após a adesão em nosso sistema, você
          permanecerá como cliente da Distribuidora de energia, não alterando,
          portanto, nada do ponto de vista operacional ou de relacionamento.
        </Typography>
      </>
    ),
    categoryId: ["detalhes"],
  },
  {
    question:
      "A quem devo recorrer em caso de assuntos referentes ao fornecimento de energia?",
    answer: (
      <>
        <Typography>
          Sua Distribuidora de energia continua sendo a responsável por qualquer
          assunto relacionado à sua conta/fornecimento. Os canais de atendimento
          da sua Distribuidora continuarão sendo o ponto de referência para
          tratar desses assuntos.
        </Typography>
      </>
    ),
    categoryId: ["servico"],
  },
  {
    question:
      "A quem devo recorrer em caso de assuntos referentes aos créditos de energia injetada?",
    answer: (
      <>
        <Typography>
          Qualquer assunto relacionado à Usina Solar e a sua participação ao
          Consórcio Energea, deverá ser tratado diretamente com a Energea
          Brasil, pelo email de atendimento contato@energea.com.
        </Typography>
      </>
    ),
    categoryId: ["servico"],
  },
  {
    question: "Se eu mudar de endereço, devo informar a Usina sobre a mudança?",
    answer: (
      <>
        <Typography>
          Sim. Cada endereço tem um registro/número de instalação diferente, que
          é oferecido pela sua Distribuidora e consta na sua conta de energia. É
          através desse número que são disponibilizados os créditos.
        </Typography>
        <Typography>
          Caso ocorra uma mudança de endereço, a Distribuidora de energia
          informará um novo número de instalação referente ao novo endereço, e
          esse número deverá ser informado à Energea Brasil para que possamos
          enviar os seus créditos para a sua nova conta.
        </Typography>
      </>
    ),
    categoryId: ["detalhes", "servico"],
  },
  {
    question:
      "Ficarei sem energia no meu imóvel caso o tempo esteja nublado ou chuvoso?",
    answer: (
      <>
        <Typography>
          Não. O sistema gera energia mesmo em dias nublados ou chuvosos, porém
          nessas condições a produção será menor. Mesmo que a sua cota na Usina
          Solar FV Energea não gere energia suficiente para o seu consumo, ainda
          assim você a receberá normalmente em seu estabelecimento. Nesse caso,
          a energia utilizada será fornecida pela Distribuidora de energia.
        </Typography>
      </>
    ),
    categoryId: ["servico"],
  },
  {
    question:
      "Posso indicar pessoas ou empresas para se cadastrarem na Usina Solar?",
    answer: (
      <>
        <Typography>
          Sim! Suas indicações serão muito bem-vindas e são um voto de
          confiança.
        </Typography>
        <Typography>
          Caso você tenha uma lista de potenciais clientes, comerciais ou
          residenciais, por favor, envie um e-mail para vendas@energea.com e
          entraremos em contato. Estamos abertos a parcerias.
        </Typography>
      </>
    ),
    categoryId: ["sobre"],
  },
  {
    question: "A Energea Brasil é uma empresa brasileira?",
    answer: (
      <>
        <Typography>
          Sim. Todas as entidades das usinas da Energea são brasileiras. A razão
          social é informada no Termo de Adesão que o cliente assina.
        </Typography>
      </>
    ),
    categoryId: ["sobre"],
  },
  {
    question:
      "Qual a diferença entre a Energea Brasil e as Distribuidoras ( Cemig, Light etc)?",
    answer: (
      <>
        <Typography>
          A Energea Brasil constrói, gerencia e opera usinas de energia solar no
          sistema de GD (Geração Distribuída), oferecendo a oportunidade do
          consumidor gerar e consumir sua própria energia renovável e usufruir
          de compartilhamento de créditos de energia que representam descontos
          na tarifa da energia injetada pela usina, na sua conta de luz.
          Enquanto a Distribuidora (Light, Cemig etc.) é responsável por todo
          serviço de distribuição de energia através da rede elétrica e suas
          atividades vinculadas.
        </Typography>
      </>
    ),
    categoryId: ["sobre"],
  },
];
class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curCategoryId: "frequentes",
      contactFormOpen: false, // eslint-disable-line
    };
  }

  render() {
    const { i18n, classes } = this.props;
    const { expanded, curCategoryId } = this.state;
    return (
      <Grid
        container
        style={{
          background: "linear-gradient(180deg, #003B6D 0%, #008A83 100%)",
        }}
        justifyContent="center"
      >
        <Grid
          item
          xl={8}
          lg={9}
          xs={12}
          style={{ marginTop: "2rem", marginBottom: "4rem" }}
        >
          <Typography
            color="primary"
            style={{
              color: "#fff",
              // textAlign: "center",
              padding: "0 1rem",
              marginTop: "2rem",
              marginBottom: "2rem",
            }}
            variant="h2"
          >
            {i18n.t("FAQ", "Frequently asked questions")}
          </Typography>
          <Grid
            container
            // justifyContent="center"
            style={{ marginBottom: "2rem", padding: "0 .5rem" }}
          >
            {faqCategories.map((faqCategory) => (
              <Grid
                key={`fa-cat-${faqCategory.id}`}
                item
                style={{ margin: ".5rem" }}
              >
                <Button
                  className={classnames([
                    classes.categoryButtons,
                    faqCategory.id === curCategoryId ? classes.selected : null,
                  ])}
                  variant="outlined"
                  onClick={() =>
                    this.setState({ curCategoryId: faqCategory.id })
                  }
                >
                  {faqCategory.label}
                </Button>
              </Grid>
            ))}
          </Grid>
          <Paper
            elevation={0}
            style={{
              backgroundColor: "rgba(255,255,255,0)",
            }}
          >
            {faqObjected.map((faqEntry, index) => {
              if (
                !faqEntry.categoryId ||
                faqEntry.categoryId.indexOf(curCategoryId) === -1
              ) {
                return null;
              }
              return (
                <Accordion
                  key={`accordion-faq-${faqEntry.categoryId}-${index}`}
                  expanded={expanded === index}
                  style={{ background: "rgba(255,255,255,0)" }}
                  onChange={() => {
                    const expandedNew = expanded === index ? null : index;
                    this.setState({ expanded: expandedNew });
                  }}
                  elevation={0}
                  className={classes.accordionBorder}
                >
                  <AccordionSummary
                    className={classes.accordion}
                    severity="info"
                    style={{
                      background: "rgba(255,255,255,0)",
                    }}
                    // style={{ width: "100%" }}
                    expandIcon={<ExpandMore style={{ color: "#fff" }} />}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 700,
                        width: "100%",
                        color: "#fff",
                      }}
                    >
                      {faqEntry.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ marginLeft: "1rem", color: "#fff" }}
                  >
                    <Typography
                      style={{ whiteSpace: "pre-wrap" }}
                      variant="body1"
                    >
                      {faqEntry.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Paper>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            size="large"
            color="secondary"
            component={Link}
            to="/register"
            variant="contained"
            style={{
              borderRadius: "50px",
              padding: "1rem 2rem",
              fontSize: "1.2rem",
              textAlign: "center",
              margin: "0 auto 4rem",
            }}
          >
            {i18n.t("saveMoney", "Save Money")}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withMobileDialog()(withTranslator(Faq))
);
