import React from "react";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";

import {
  Backdrop,
  CircularProgress,
  Grid,
  Hidden,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";

import withTranslator from "./hocs/withTranslator";
import withSnackbar from "./hocs/withSnackbar";
import ProfileForm from "./ProfileForm";
import theme from "../theme";

const updateContactMutation = loader("../mutations/UpdateBrContact.graphql");
// const createContactMutation = loader("../mutations/CreateBrContact.graphql");

const styles = () => ({});

const Settings = (props) => {
  const navigate = useNavigate();

  const { data, i18n } = props;
  if (data.loading) return null;

  let tabValue =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
  if (tabValue === "settings" || !tabValue) {
    tabValue = "contact-information";
  }
  const navObjects = [
    {
      label: i18n.t("personalInfo", "Personal Info"), // This is where contact can change their address, phone number, etc. Also where they can upload license or fill out other information we are missing
      value: "contact-information",
    },
    {
      label: "Consumer Units", // This is where contact can edit CU information if there isn't a TOA already made for it. Once there is, lock it down
      value: "consumer-units",
      hide: true,
    },
    {
      label: "Payment Options", // This is where contact can elect for auto-pay, etc.
      value: "payment-options",
      hide: true,
    },
    {
      label: "Permissions", // This is where contact can invite other contacts to view this customer and they can assign their role
      value: "permissions",
      hide: true,
      // hide: user does not have permission,
    },
    {
      label: "Security", // This is where contact can change their email/password/mfa
      value: "security",
      hide: true,
    },
  ].filter((el) => !!el);

  const handleChange = (event, newValue) => {
    const sPath = typeof newValue === "object" ? event.target.value : newValue;
    navigate(`/settings/${sPath}`);
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid
          item
          container
          style={{ padding: "1em", minHeight: "100%" }}
          lg={8}
          md={10}
          xs={12}
          alignContent="flex-start"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <Typography variant="h4">
              {i18n.t("settings", "Settings")}
            </Typography>
          </Grid>
          <Grid item container justifyContent="center">
            <Grid
              item
              xs={12}
              lg={3}
              xl={2}
              style={{ textAlign: "center", padding: ".6rem" }}
            >
              <Grid item>
                <Hidden mdDown>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={tabValue}
                    onChange={handleChange}
                    TabIndicatorProps={{
                      style: { left: "0px" },
                    }}
                  >
                    {navObjects
                      .filter((el) => !el.hide)
                      .map((navObject) => (
                        <Tab
                          key={`nav-tab-${navObject.value}`}
                          label={navObject.label}
                          value={navObject.value}
                        />
                      ))}
                  </Tabs>
                </Hidden>
              </Grid>
              <Hidden lgUp>
                <Grid style={{ padding: "1em 0" }} item xs={12}>
                  <Select
                    style={{ width: "100%" }}
                    fullWidth
                    variant="outlined"
                    value={tabValue}
                    onChange={handleChange}
                  >
                    {navObjects
                      .filter((el) => !el.hide)
                      .map((navObject) => (
                        <MenuItem
                          key={`mobile-settings-nav-${navObject.value}`}
                          style={{ width: "100%" }}
                          value={navObject.value}
                        >
                          <Typography variant="inherit">
                            {navObject.label}
                          </Typography>
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              </Hidden>
            </Grid>
            <Grid item xs={12} lg={9} xl={10} style={{ padding: ".6rem" }}>
              <Paper style={{ padding: "1em" }} elevation={1}>
                <Routes>
                  <Route
                    exact
                    path="/settings"
                    element={<Navigate to="/settings/contact-information" />}
                  />
                  <Route
                    path="/contact-information"
                    element={<ProfileForm parent={{ props }} />}
                  />
                  {/* <Route
                  path="/consumer-units"
                  element={<ProfileForm parent={{ props }} />}
                /> */}
                  {/* <Route
                  path="/payment-options"
                  element={<ProfileForm parent={{ props }} />}
                /> */}
                  {/* <Route
                  path="/permissions"
                  element={<ProfileForm parent={{ props }} />}
                /> */}
                  {/* <Route
                  path="/security"
                  element={<ProfileForm parent={{ props }} />}
                /> */}
                  <Route
                    exact
                    path="/*"
                    element={<Navigate to="/settings/contact-information" />}
                  />
                </Routes>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop
        style={{ zIndex: theme.zIndex.modal + 1 }}
        open={!!data.loading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </>
  );
};

export default withSnackbar(
  graphql(updateContactMutation, { name: "updateBrContact" })(
    withStyles(styles, { withTheme: true })(withTranslator(Settings))
  )
);
