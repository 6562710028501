import React, { Component, Fragment } from "react";
// import { graphql } from "react-apollo";
import { Link } from "react-router-dom";
import {
  Fade,
  Button,
  Grid,
  Typography,
  withMobileDialog,
} from "@material-ui/core";
import { LocationOn, Notes } from "@material-ui/icons";

import { withStyles } from "@material-ui/core/styles";
import cloudinary from "cloudinary-core";
// import { loader } from "graphql.macro";
import ProgressTracker from "./ProgressTrackers";
import withTranslator from "./hocs/withTranslator";
import "../index.css";
// const projectsQuery = loader("../queries/Projects.graphql");

const cl = new cloudinary.Cloudinary({
  cloud_name: "dwelloptimal",
  secure: true,
});
const styles = (theme) => ({});
const projects = [
  {
    name: "Itaguaí",
    percentFilled: 100,
    summary:
      "Itaguaí é um projeto solar comunitário de 4.1 MW que atende pequenas e médias empresas em Rio de Janeiro.",
    carbonReduction: 7.11,
    publicId: "Itaguai2",
    location: "Rio De Janeiro, Brasil",
  },
  // {
  //   name: "Salinas",
  //   percentFilled: 66,
  //   summary:
  //     "Salinas é um projeto solar comunitário do 5 MW que atende pequenas e médias empresas em Minas Gerais.",
  //   carbonReduction: 707,
  //   publicId: "energea/br-images/banner-9_1",
  //   location: "Minas Gerais, Brasil",
  // },
  {
    name: "Iguatama",
    summary:
      "Iguatama é um projeto solar comunitário de 2.2 MW que atende pequenas e médias empresas em Minas Gerais.",
    carbonReduction: 6.83,
    percentFilled: 96,
    publicId: "energea/br-images/drilldown",
    location: "Minas Gerais, Brasil",
  },
  {
    name: "Pedra Do Indaiá",
    summary:
      "Pedra do Indaiá é uma usina solar de 3 MW que está localizada na Fazenda Tapera em Pedra do Indaiá, Minas Gerais.",
    carbonReduction: 1.31,
    percentFilled: 48,
    publicId: "energea/br-images/Pedra2",
    location: "Minas Gerais, Brasil",
    backgroundPosition: "bottom",
  },
  {
    name: "Micros I",
    summary:
      "Micros I é um conjunto de doze instalações solares de 100 kW localizadas na região serrana do Rio de Janeiro.",
    carbonReduction: 1.42,
    percentFilled: 100,
    publicId: "energea/projects/images/PHOTO-2024-07-24-12-10-53_copy_lvuczi",
    location: "Rio De Janeiro, Brasil",
  },
  {
    name: "Três Pontas",
    summary:
      "Três Pontas é um projeto solar comunitário de 2.9 MW que atende pequenas e médias empresas em Minas Gerais.",
    carbonReduction: 1.02,
    percentFilled: 52,
    publicId: "energea/portfolios/images/bkmoa7dky4jtmykt3gke",
    location: "Minas Gerais, Brasil",
  },
];

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactFormOpen: false, // eslint-disable-line
      curProject: 0,
    };
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState({
        curProject: (this.state.curProject + 1) % projects.length,
      });
    }, 6000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const { dark, i18n, fullScreen } = this.props;
    const { curProject } = this.state;
    const timeout = 1500;
    return (
      <>
        <Grid id="projetos" container style={{ width: "100%" }}>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center"
              style={{
                float: "right",
                width: "100%",
                height: "100vh",
                maxHeight: "800px",
                minHeight: "680px",
                right: 0,
              }}
            >
              <div
                style={{
                  background:
                    "linear-gradient(251.89deg, rgba(27, 44, 74, 0) 6.99%, #1B2C4A 98.57%)",
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  maxHeight: "800px",
                  minHeight: "680px",
                  zIndex: 11,
                }}
              ></div>
              {projects.map((el, index) => {
                const imageUrl = cl.url(el.publicId, {
                  width: fullScreen ? "1200" : "1920",
                  crop: "scale",
                  format: "WebP",
                  quality: "auto",
                });

                return (
                  <Grid
                    key={`project-list-${el.name}-${index}`}
                    container
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      maxHeight: "800px",
                      minHeight: "680px",
                      // zIndex: index === curProject ? 100 : 1, // Higher z-index for the current project
                      // opacity: index === curProject ? 1 : 0, // Optional: hide non-active projects
                      pointerEvents: index === curProject ? "auto" : "none", // Disable interaction with non-active projects
                    }}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Fade timeout={timeout} in={index === curProject}>
                      <figure
                        style={{
                          backgroundImage: `url(${imageUrl})`,
                          backgroundSize: "cover",
                          backgroundPosition: el.backgroundPosition || "center",
                          margin: 0,
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          zIndex: 10,
                        }}
                      />
                    </Fade>

                    <Grid
                      item
                      xl={8}
                      lg={9}
                      xs={12}
                      style={{ zIndex: 11, padding: "1rem" }}
                    >
                      <Fade timeout={timeout} in={index === curProject}>
                        <Grid item style={{ color: "#fff" }}>
                          <Typography
                            style={{
                              color: dark ? "#fff" : null,
                              marginTop: "2rem",
                              marginBottom: "3rem",
                              width: "100%",
                              textShadow:
                                index === 0 && "rgba(0,0,0,.5) 0 0 12px",
                            }}
                            color="primary"
                            variant="h2"
                          >
                            {i18n.t("projects", "Projetos")}
                          </Typography>
                          <Typography variant="h3" paragraph>
                            <b>{el.name}</b>
                          </Typography>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                  <LocationOn
                                    style={{
                                      color: "#fff",
                                      width: "1rem",
                                      height: "1rem",
                                      marginRight: ".25rem",
                                    }}
                                  />
                                </Grid>
                                <Grid item xs>
                                  <Typography variant="body1">
                                    <div style={{ wordBreak: "break-word" }}>
                                      {el.location}
                                    </div>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={8}
                              lg={6}
                              style={{ minHeight: "96px" }}
                            >
                              <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                  <Notes
                                    style={{
                                      color: "#fff",
                                      width: "1rem",
                                      height: "1rem",
                                      marginRight: ".25rem",
                                    }}
                                  />
                                </Grid>
                                <Grid item xs>
                                  <Typography variant="body1">
                                    <div style={{ wordBreak: "break-word" }}>
                                      {el.summary}
                                    </div>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            style={{ width: "100%" }}
                            alignItems="center"
                            spacing={2}
                          >
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{
                                  fontWeight: 700,
                                  fontSize: "72px",
                                  color: "rgba(255,255,255,0)",
                                  "-webkit-text-stroke-width": "1px",
                                  "-webkit-text-stroke-color": "#fff",
                                }}
                              >
                                {el.carbonReduction}K
                              </Typography>
                            </Grid>
                            <Grid container item xs direction="column">
                              <Grid item>
                                <Typography variant="h2">
                                  {i18n.t("tons", "tons")}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  de emissões do CO2 evitados
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              md={8}
                              lg={6}
                              style={{ marginBottom: "4rem" }}
                            >
                              <ProgressTracker current={el.percentFilled} />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              textAlign: fullScreen && "center",
                              position: "relative",
                            }}
                          >
                            <Button
                              size="large"
                              color="secondary"
                              component={Link}
                              to="/register"
                              variant="contained"
                              style={{
                                position: "relative",
                                borderRadius: "50px",
                                padding: "1rem 2rem",
                                fontSize: "1.2rem",
                                textAlign: "center",
                                margin: "0 auto 4rem",
                                zIndex: index === curProject ? 10001 : 10000,
                              }}
                            >
                              {i18n.t("saveMoney", "Save Money")}
                            </Button>
                          </Grid>
                        </Grid>
                      </Fade>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withMobileDialog()(withTranslator(Projects))
);
