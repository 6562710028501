const constants = {
  discountRate: 0.18,
  termsOfServiceUrl:
    "https://res.cloudinary.com/dwelloptimal/image/upload/v1656340153/energea/br-documents/Termos_de_Servic%CC%A7o_-_Energea_Brasil.pdf",
  privacyPolicyUrl:
    "https://res.cloudinary.com/dwelloptimal/image/upload/v1654184154/energea/br-documents/Poli%CC%81tica_de_privacidade_-_Energea_BR_01.06.2022.pdf",
  googleAnalyticsId: "UA-154452745-1",
  linkedInUrl: "https://www.linkedin.com/company/energea-brasil/",
  facebookUrl: "https://www.facebook.com/energeabrasil",
  instagramUrl: "https://www.instagram.com/energea.brasil/",
  headerLogoUrl:
    "https://res.cloudinary.com/dwelloptimal/image/upload/v1597421503/energea/LogoEnergeaBrasilWhiteSemFundo.webp",
  footerLogoUrl:
    "https://res.cloudinary.com/dwelloptimal/image/upload/v1597421503/energea/LogoEnergeaBrasilWhiteSemFundo.webp",
  headerLogoWidth: "120px",
  footerLogoWidth: "100px",
  // howToVideoTag: 'dfwny22kjavvxhhuzdpx', // updated May 28th, 2020
  howToVideoTag: "cqqi4tv4frl4h1utpuop", // updated July 8th, 2020
  missingImagePublicId: "site/missing_image",
  missingImageUrl:
    "https://res.cloudinary.com/dwelloptimal/image/upload/v1555681708/site/missing_image.webp",
  globalCompanyName: "Energea",
  roles: [
    {
      label: "Owner",
      description: "Account owner can pay invoices, and manage users.",
      value: "owner",
    },
    {
      label: "Admin",
      description:
        "Account admins can view and pay invoices, but not manage users.",
      value: "admin",
    },
    {
      label: "Member",
      description:
        "Account members can view invoices, and performance, but access is read only.",
      value: "member",
    },
    {
      label: "Salesperson",
      description:
        "Account members can view invoices, and performance, but access is read only.",
      value: "salesPerson",
    },
  ],
};

const findWithAttr = (array, attr, value) => {
  if (!array || !attr || !value) return null;
  for (let i = 0; i < array.length; i += 1) {
    if (array[String(i)][String(attr)].toString() === value.toString()) {
      return array[String(i)];
    }
  }
  return null;
};

module.exports = {
  constants,
  findWithAttr,
};
