import React, { Component } from "react";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  TextField,
  Typography,
  withMobileDialog,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";

import validator from "validator";

import withSnackbar from "./hocs/withSnackbar";

const sendContactUsEmailMutation = loader(
  "../mutations/SendContactUsEmail.graphql"
);

const styles = (theme) => ({});

class ContactDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactFormOpen: false, // eslint-disable-line
    };
    this.update = this.update.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  update(attr) {
    const that = this;
    return (event) => {
      that.setState({ [attr]: event.target.value });
    };
  }

  sendEmail() {
    const { mutate, snackbar } = this.props;
    const { firstName, lastName, email, message } = this.state;
    mutate({
      variables: { email, firstName, lastName, message },
    })
      .catch((res) => {
        const errors = res.graphQLErrors.map((error) => error.message);
        console.log(errors);
        this.setState({ loading: false });
        snackbar.setState({
          snackbarMessage: `Sorry, error sending contact email! Please try again later.`,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      })
      .then(() => {
        snackbar.setState({
          snackbarMessage: `Email sent. We'll be in touch shortly!`,
          snackbarOpen: true,
          snackbarVariant: "success",
        });
        this.setState({
          firstName: null,
          lastName: null,
          email: null,
          message: null,
          loading: false,
        });
      });
  }

  render() {
    const { fullScreen, open, onClose } = this.props;
    const { firstName, lastName, message, email } = this.state;
    return (
      <Dialog fullScreen={fullScreen} open={!!open} onClose={onClose}>
        <DialogTitle style={{ textAlign: "right" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography color="primary" variant="h2">
                Contato
              </Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={onClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                required
                style={{ background: "#fff" }}
                variant="outlined"
                label="Nome"
                value={firstName || ""}
                onChange={this.update("firstName")}
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                required
                style={{ background: "#fff" }}
                variant="outlined"
                label="Último nome"
                value={lastName || ""}
                onChange={this.update("lastName")}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                style={{ background: "#fff" }}
                variant="outlined"
                label="E-mail"
                value={email || ""}
                onChange={this.update("email")}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                multiline
                style={{ background: "#fff" }}
                variant="outlined"
                label="Mensagem"
                rows="4"
                value={message || ""}
                onChange={this.update("message")}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                style={{
                  marginBottom: "1rem",
                  fontWeight: "bold",
                  width: "100%",
                  borderRadius: "50px",
                }}
                onClick={this.sendEmail}
                color="secondary"
                disabled={
                  !firstName ||
                  !lastName ||
                  !message ||
                  firstName === "" ||
                  lastName === "" ||
                  message === "" ||
                  !validator.isEmail(email)
                }
                variant="contained"
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withSnackbar(
  graphql(sendContactUsEmailMutation)(
    withStyles(styles, { withTheme: true })(withMobileDialog()(ContactDialog))
  )
);
