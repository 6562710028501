import React, { useState } from "react";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import { CheckCircle, Close, Error, GetApp } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import moment from "moment";

import { findWithAttr } from "../global";
import ExcelReader from "./ExcelReader";
import withTranslator from "./hocs/withTranslator";
import withSnackbar from "./hocs/withSnackbar";

const logToSlackMutation = loader("../mutations/LogToSlack.graphql");
const brCustomerUploadMutation = loader(
  "../mutations/BrCustomerUpload.graphql"
);

const styles = (theme) => ({});

const attrs = [
  {
    name: "installationCode",
    format: (val) => String(val),
    label: "Código instalação",
    align: "left",
    validate: (val) => !!val,
  },
  {
    name: "utilityCustomerCode",
    format: (val) => String(val),
    label: "Código do cliente",
    align: "left",
    validate: (val) => !!val,
  },
  {
    name: "tariffClass",
    format: (val) => val,
    label: "Classe tarifária",
    align: "right",
    validate: (val) => !!val,
  },
  {
    name: "brVoltagePhase",
    format: (val) => val,
    label: "Conexão",
    align: "right",
    validate: (val) => !!val,
  },
  {
    name: "utilityCompany",
    format: (val) => val,
    label: "Distribuidora",
    align: "right",
    validate: (val) => !!val,
  },
  {
    name: "discountRate",
    format: (val) => val,
    label: "Desconto",
    align: "right",
    validate: (val) => !!val || val === 0,
  },
  {
    name: "address1",
    format: (val) => val,
    label: "Endereço",
    align: "right",
    validate: (val) => !!val,
  },
  {
    name: "address2",
    format: (val) => val,
    label: "Complemento unidade",
    align: "right",
    validate: (val) => true,
  },
  {
    name: "postalCode",
    format: (val) => val,
    label: "CEP unidade",
    align: "right",
    validate: (val) => !!val,
  },
  {
    name: "district",
    format: (val) => val,
    label: "Bairro unidade",
    align: "right",
    validate: (val) => !!val,
  },
  {
    name: "city",
    format: (val) => val,
    label: "Cidade unidade",
    align: "right",
    validate: (val) => !!val,
  },
  {
    name: "state",
    format: (val) => val,
    label: "Estado unidade",
    align: "right",
    validate: (val) => !!val,
  },
  {
    name: "customerName",
    format: (val) => val,
    label: "Nome do cliente",
    align: "right",
    validate: (val) => !!val,
  },
  {
    name: "type",
    format: (val) => val,
    label: "Tipo",
    align: "right",
    validate: (val) => !!val,
  },
  {
    name: "cnpj",
    format: (val) => val,
    label: "CNPJ",
    align: "right",
    validate: (val, row) => {
      if (row.type?.toLowerCase() === "cnpj") {
        return !!val;
      }
      return true;
    },
  },
  {
    name: "cpf",
    format: (val) => val,
    label: "CPF",
    align: "right",
    validate: (val) => !!val,
  },
  {
    name: "nire",
    format: (val) => val,
    label: "NIRE",
    align: "right",
    validate: (val, row) => {
      if (row.type?.toLowerCase() === "cnpj") {
        return !!val;
      }
      return true;
    },
  },
  {
    name: "primaryContactFirstName",
    format: (val) => val,
    label: "Primeiro nome do contato principal",
    align: "right",
    validate: (val) => !!val,
  },
  {
    name: "primaryContactLastName",
    format: (val) => val,
    label: "Sobrenome do contato principal",
    align: "right",
    validate: (val) => !!val,
  },
  {
    name: "primaryContactEmail",
    format: (val) => val,
    label: "Email do contato principal",
    align: "right",
    validate: (val) => !!val,
  },
  {
    name: "primaryContactPhoneNumber",
    format: (val) => val,
    label: "Número de telefone do contato principal",
    align: "right",
    validate: (val) => !!val,
  },
  {
    name: "janConsumption",
    format: (val) => val,
    label: "Janeiro consumo (kWh)",
    align: "right",
  },
  {
    name: "febConsumption",
    format: (val) => val,
    label: "Fevereiro consumo (kWh)",
    align: "right",
  },
  {
    name: "marConsumption",
    format: (val) => val,
    label: "Marco consumo (kWh)",
    align: "right",
  },
  {
    name: "aprConsumption",
    format: (val) => val,
    label: "Abril consumo (kWh)",
    align: "right",
  },
  {
    name: "mayConsumption",
    format: (val) => val,
    label: "Maio consumo (kWh)",
    align: "right",
  },
  {
    name: "junConsumption",
    format: (val) => val,
    label: "Junho consumo (kWh)",
    align: "right",
  },
  {
    name: "julConsumption",
    format: (val) => val,
    label: "Julho consumo (kWh)",
    align: "right",
  },
  {
    name: "augConsumption",
    format: (val) => val,
    label: "Agosto consumo (kWh)",
    align: "right",
  },
  {
    name: "sepConsumption",
    format: (val) => val,
    label: "Setembro consumo (kWh)",
    align: "right",
  },
  {
    name: "octConsumption",
    format: (val) => val,
    label: "Outubro consumo (kWh)",
    align: "right",
  },
  {
    name: "novConsumption",
    format: (val) => val,
    label: "Novembro consumo (kWh)",
    align: "right",
  },
  {
    name: "decConsumption",
    format: (val) => val,
    label: "Dezembro consumo (kWh)",
    align: "right",
  },
  {
    name: "termsOfAdhesionStartDt",
    format: (val) => (val ? moment(val).format("YYYY-MM-DD") : ""),
    label: "Termo de adesão data de início",
    align: "right",
    // validate: (val) => !!val,
  },
  {
    name: "termsOfAdhesionSignatureDt",
    format: (val) => (val ? moment(val).format("YYYY-MM-DD") : ""),
    label: "Termo de adesão data da assinatura",
    align: "right",
    // validate: (val) => !!val,
  },
];

const PartnerConsumerUnitUploadDialog = (props) => {
  const [data, setData] = useState(null);
  const [uploadErrors, setUploadErrors] = useState(false);
  const [reviewed, setReviewed] = useState(false);
  const [loading, setLoading] = useState(false);
  const { snackbar, logToSlack, uploadBrCustomers, i18n, handleClose, open } =
    props;

  const save = () => {
    setLoading(true);
    uploadBrCustomers({
      variables: {
        input: {
          brSalesPersonId: parseInt(props.brSalesPersonId, 10),
          data,
        },
      },
    }).then(
      (res) => {
        setLoading(false);
        snackbar.setState({
          snackbarMessage: `Sucesso`,
          snackbarOpen: true,
          snackbarVariant: "success",
        });
        logToSlack({
          variables: {
            input: {
              title: "Partner uploaded consumer unit list",
              type: "credit-management",
              data: [
                {
                  label: "Message for Credit Mgmt team",
                  value:
                    "Reach out to the partner to get the list of signed Terms of Adhesion documents if applicable and upload them to the CMS",
                },
                {
                  label: "CU installation codes",
                  value: data.map((cu) => cu.installationCode).join(", "),
                },
                {
                  label: "Sales Person ID",
                  value: String(props.brSalesPersonId),
                },
              ],
            },
          },
        });
        setData(null);
        setReviewed(false);
        handleClose();
      },
      (e) => {
        setLoading(false);
        let errorMsg = e;
        if (e.graphQLErrors && e.graphQLErrors[0]) {
          errorMsg = e.graphQLErrors[0].message;
        }
        snackbar.setState({
          snackbarMessage: `Houve um erro ao salvar suas unidades consumidoras. Entre em contato com contato@energea.com para obter ajuda.`,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
        logToSlack({
          variables: {
            input: {
              title: "Error for partner uploading consumer unit list",
              type: "credit-management",
              data: [
                {
                  label: "Sales Person ID",
                  value: String(props.brSalesPersonId),
                },
                {
                  label: "Error",
                  value: String(errorMsg),
                },
              ],
            },
          },
        });
      }
    );
  };

  const handleData = (data) => {
    const stringFields = [
      "utilityCustomerCode",
      "installationCode",
      "cpf",
      "cnpj",
      "nire",
      "primaryContactFirstName",
      "primaryContactLastName",
      "primaryContactEmail",
      "primaryContactPhoneNumber",
      "type",
    ];
    setUploadErrors(false);
    const lintedData = data.map((row) => {
      const lintedRow = {};
      Object.keys(row).forEach((key) => {
        const entryAttrData = findWithAttr(attrs, "label", key);
        if (!entryAttrData) {
          console.log("Missing attr detected", key);
        } else {
          let lintedValue = row[String(key)];
          if (stringFields.indexOf(entryAttrData.name) > -1) {
            lintedValue = String(lintedValue);
          }
          lintedRow[entryAttrData.name] = lintedValue;
        }
      });
      lintedRow.brConsumerUnitStage = "em análise";
      return lintedRow;
    });
    setData(lintedData);
  };

  const renderSubmit = () => {
    return (
      <Alert severity={!reviewed ? "warning" : "success"}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!reviewed}
              onChange={() => setReviewed(!reviewed)}
              disabled={uploadErrors}
            />
          }
          label="Eu revisei os dados na tabela abaixo."
        />
        <Button
          onClick={save}
          disabled={!reviewed || loading || uploadErrors}
          variant="contained"
          size="large"
          color="secondary"
        >
          {loading ? <CircularProgress /> : "Salvar"}
        </Button>
      </Alert>
    );
  };

  const renderData = () => {
    return (
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "1em" }} align="center">
              Status
            </TableCell>
            {attrs.map((attr) => (
              <TableCell align={attr.align || "center"}>{attr.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            let errors = false;
            const cellJsx = attrs.map((attr) => {
              const val = row[String(attr.name)];
              const validated = attr.validate ? attr.validate(val, row) : true;
              console.log(validated, attr.name);
              if (!errors && !validated) {
                errors = true;
                if (!uploadErrors) {
                  setUploadErrors(true);
                }
              }
              const lintedVal = attr.format ? attr.format(val) : val;
              return (
                <TableCell
                  align={attr.align || "center"}
                  style={{ backgroundColor: validated ? null : "red" }}
                >
                  {lintedVal}
                </TableCell>
              );
            });
            return (
              <TableRow
                onClick={() => {}}
                // style={{ cursor: 'pointer' }}
                key={`${row.email}`}
              >
                <TableCell style={{ paddingRight: 0 }} align="right">
                  {errors ? (
                    <Error style={{ color: "red" }} />
                  ) : (
                    <CheckCircle style={{ color: "green" }} />
                  )}
                </TableCell>
                {cellJsx}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  return (
    <Dialog open={!!open} fullScreen>
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5">
              {i18n.t("addConsumerUnit", "Add Consumer Unit")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={() => handleClose()}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          // justifyContent="center"
          // alignItems="center"
          direction="column"
        >
          <Grid item>
            <Alert severity="info">
              <Typography>
                Envie uma versão completa do modelo de Excel fornecido abaixo.
                Se houver dados faltando ou inesperados, eles serão destacados
                em vermelho assim que você clicar no botão 'Processar arquivo'.
                Razões comuns para a falha ao salvar os dados incluem uma das
                unidades consumidoras já ter sido criada ou sua conta não ter
                sido configurada com acesso a um plano de energia que foi
                incluído no documento Excel. Se for esse o caso, entre em
                contato com contato@energea.com para obter ajuda.
              </Typography>
            </Alert>
          </Grid>
          <Grid item style={{ padding: "1em" }}>
            <Button
              component="a"
              variant="contained"
              href="/csv-templates/listaDeUnidadesConsumidoras.xlsx"
              download
            >
              <GetApp />
              Clique para baixar o modelo de Excel.
            </Button>
          </Grid>
          <Grid item>
            <ExcelReader handleData={handleData} />
          </Grid>
          <Grid item>{data ? renderSubmit() : null}</Grid>
          <Grid item xs={12}>
            {data ? renderData() : null}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default withSnackbar(
  withStyles(styles)(
    graphql(brCustomerUploadMutation, { name: "uploadBrCustomers" })(
      graphql(logToSlackMutation, { name: "logToSlack" })(
        withTranslator(PartnerConsumerUnitUploadDialog)
      )
    )
  )
);
