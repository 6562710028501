import React, { useState } from "react";
import { Link } from "react-router-dom";
// import cloudinary from "cloudinary-core";
import { useOktaAuth } from "@okta/okta-react";
import {
  Divider,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  withStyles,
} from "@material-ui/core";
import {
  AccountCircle,
  ArrowDropDown,
  Settings,
  TrendingUp,
} from "@material-ui/icons";
import withTranslator from "./hocs/withTranslator";

// const cl = new cloudinary.Cloudinary({
//   cloud_name: process.env.CLOUDINARY_CLOUD_NAME,
//   secure: true,
// });

const styles = (theme) => ({
  menuAvatarBtn: {
    height: "2.35em",
    width: "2.35em",
    margin: "auto",
    marginTop: "2px",
  },
  boldTxt: {
    fontWeight: "bold",
  },
});

const AccountMenuBtn = ({ classes, darkMode, i18n, theme }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { oktaAuth } = useOktaAuth();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const renderAvatar = () => {
    // If user and user.primaryImage.public_id exist, return Avatar with cloudinary URL
    return <AccountCircle style={{ fontSize: "1.25em", color: "white" }} />;
  };

  return (
    <>
      <IconButton
        aria-label="User Actions"
        aria-haspopup="true"
        className={classes.menuAvatarBtn}
        onClick={handleClick}
        color="primary"
        style={{ color: darkMode ? "white" : null }}
      >
        {renderAvatar()}
        <ArrowDropDown
          style={{
            position: "absolute",
            right: "-5px",
            color: "rgba(255,255,255,.8)",
          }}
        />
      </IconButton>
      <Menu
        id="account-menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* MenuItems wrapped in Link components */}
        <MenuItem component={Link} to="/dashboard">
          <ListItemIcon>
            <TrendingUp />
          </ListItemIcon>
          <ListItemText primary={i18n.t("myDashboard", "My Dashboard")} />
        </MenuItem>
        <MenuItem component={Link} to="/settings">
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary={i18n.t("settings", "Settings")} />
        </MenuItem>
        {/* Similar structure to the class component */}
        <Divider
          style={{
            height: 0,
            width: "100%",
            borderTop: `thin solid ${theme.palette.divider}`,
          }}
        />
        <MenuItem
          onClick={() => {
            oktaAuth.signOut({
              postLogoutRedirectUri: `${window.location.origin}/login`,
            });
          }}
        >
          <ListItemIcon>
            <Icon className="fas fa-sign-out-alt" />
          </ListItemIcon>
          <ListItemText primary={i18n.t("logOut", "Log Out")} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(
  withTranslator(AccountMenuBtn)
);
