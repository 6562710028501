import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import {
  Avatar,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  withMobileDialog,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import withTranslator from "./hocs/withTranslator";

const styles = () => ({});

class WhoWeAre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactFormOpen: false, // eslint-disable-line
    };
  }

  getList() {
    return [
      "Empoderar consumidores na escolha pelo consumo de energia limpa, renovável e mais barata.",
      "Ajudar o nosso planeta a enfrentar os desafios climáticos.",
      "Reduzir a matriz energética poluente.",
      "Acelerar a atual transição energética para uma matriz limpa, renovável e sem impacto ambiental.",
      "Agregar valor sustentável para empresas e pessoas que valorizam a consciência ecológica.",
      "Gerar oportunidades de empregos e negócios.",
    ];
  }

  render() {
    const { fullScreen, theme, i18n } = this.props;
    return (
      <>
        <Grid
          container
          style={{ width: "100%", background: "#fff", position: "relative" }}
          justifyContent="center"
        >
          <div
            id="quem-somos"
            container
            style={{ position: "absolute", top: "-80px" }}
          ></div>
          <Grid container item style={{ width: "100%" }} xl={8} lg={9} xs={12}>
            <Grid
              item
              md
              style={{
                marginTop: "2.5rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
            >
              <Typography
                color="primary"
                style={{ marginBottom: "1.5rem" }}
                variant="h2"
              >
                {i18n.t("aboutUs", "About us")}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  marginBottom: "1rem",
                  color: theme.palette.blue3.main,
                }}
              >
                A Energea Brasil opera pensando no amanhã e fazemos isso
                incentivando o uso de energia solar, tornando a tecnologia
                fotovoltaica acessível para todos.
              </Typography>
              <Typography
                variant="body1"
                style={{ color: theme.palette.blue3.main }}
              >
                Através das nossas{" "}
                <b>
                  Usinas de Energia Solar no Brasil, África e Estados Unidos
                </b>
                , nossos associados geram e consomem{" "}
                <b>sua própria energia de forma simplificada e com economia</b>.
              </Typography>
              <Grid
                item
                style={{
                  borderRadius: "8px 0 0 8px",
                  marginTop: "1.5rem",
                  marginRight: fullScreen ? "1.5rem" : null,
                  background:
                    "linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0)), linear-gradient(to right, #6884AB, rgba(255,255,255,0))",
                  backgroundOrigin: "padding-box, border-box",
                  backgroundRepeat: "no-repeat" /* this is important */,
                  border: "1px solid transparent",
                  // position: "relative",
                }}
              >
                <Grid
                  item
                  style={{
                    width: "100%",
                    height: "100%",
                    background: "#fff",
                    backgroundOrigin: "padding-box, border-box",
                    backgroundRepeat: "no-repeat" /* this is important */,
                    borderRadius: "7px",
                    padding: "1.25rem 0 1.25rem 1.25rem",
                  }}
                >
                  <Grid
                    item
                    style={{
                      background: theme.palette.blue3.main,
                      color: "#fff",
                      padding: "1.5rem",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography variant="body1" gutterBottom>
                      <b>
                        Com o nosso trabalho no Brasil, queremos mostrar que
                        você também pode escolher de onde vem a energia que você
                        consome. Seja Energea!
                      </b>
                    </Typography>
                    <Typography
                      variant="h3"
                      style={{ color: "#8CC63F", fontSize: "28px" }}
                      component={Link}
                      to={"/register"}
                    >
                      Vamos juntos?
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid item>
                <Grid container alignItems="flex-end">
                  <Grid item>
                    <img
                      style={{ verticalAlign: "bottom" }}
                      src="https://res.cloudinary.com/dwelloptimal/image/upload/v1653055982/energea/br-images/Group_137.webp"
                      alt="Group of people"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
            <Grid
              item
              md
              style={{
                marginTop: fullScreen ? "3rem" : "6rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
            >
              <Typography
                variant="body1"
                style={{ color: theme.palette.blue3.main }}
              >
                <b>Nossos projetos contribuem para:</b>
              </Typography>
              <List>
                {this.getList().map((el, i) => {
                  return (
                    <ListItem
                      key={`who-we-are-${i}`}
                      disableGutters
                      // disablePadding={true}
                    >
                      <ListItemAvatar style={{ minWidth: "48px" }}>
                        <Avatar style={{ background: "#fff" }}>
                          <i
                            style={{
                              // background:
                              //   "linear-gradient(180deg, #8CC63F 0%, #007F83 100%);",

                              // font-size: 40px;
                              lineHeight: "24px",
                              background:
                                "linear-gradient(to bottom, #8CC63F 0%, #007F83 100%)",
                              "-webkit-background-clip": "text",
                              "-moz-background-clip": "text",
                              "background-clip": "text",
                              "-webkit-text-fill-color": "transparent",
                            }}
                            className="far fa-circle-check"
                          ></i>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        style={{
                          color: theme.palette.blue3.main,
                          marginTop: "2px",
                          marginBottom: "2px",
                        }}
                        primary={el}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
            <Hidden mdUp>
              <Grid item xs={12} style={{ margin: "2rem 0" }}>
                <img
                  style={{ width: "100%" }}
                  src="https://res.cloudinary.com/dwelloptimal/image/upload/v1654708532/energea/br-images/ma%CC%83os.webp"
                  alt="Maos"
                />
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withMobileDialog()(withTranslator(WhoWeAre))
);
